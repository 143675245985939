<template>
  <AppLayout>
    <div class="content-warp">
      <div class="warp-title">
        <div class="warp1-title1">
          <p class="warp-title-text1">11</p>
          <p class="warp-title-text" @click="$router.back(-1)">
            <span> <img src="../../assets/images/fh.png" alt="" /></span
            >服务与支持中心
          </p>
          <p class="warp-title-content">{{ title }}</p>
          <el-divider
            style="width: 1060px; margin-left: 74px; background-color: #e1e1e"
          />
          <div class="warp-img" v-html="details"></div>
          <div class="warp-img-1">资料下载</div>
          <div class="warp-img-2" v-for="item in files" @click="openFile(item)">{{item.name}}</div>
          <div class="warp-2">1</div>
        </div>
      </div>
      <div class="warp-i">1</div>
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Guarantee } from "../../api/support/index.js";
import AppLayout from "@/components/AppLayout";
import HomeBackup from "@/views/home/components/HomeBackup";
import { useRoute } from "vue-router";

const route = useRoute();
let id = ref(route.params.id);
const details = ref();
const title = ref();
const files = ref([]); // 资料列表
const openFile = (item) => {
  const link = document.createElement('a');
  link.href = item.url;
  console.log(link.href);
  link.setAttribute('download', item.name);
  link.click();
};
const getGuarantee = async () => {
  try {
    let data = await Guarantee(id.value);
    data.data.details = data.data.details.replace(
      /<video/g,
      "<video style='width:961px;height:510px'"
    );
    // data.data.details = data.data.details.replace(
    //   /<li/g,
    //   "<li style='list-style: decimal;margin-left: 30px;'"
    // );
    details.value = data.data.details;
    title.value = data.data.title;
    data.data.productUrl.split(",").forEach((item, index) => {
      // 格式化文件名
      const itemArray = item.split('/')
      const fileNameFull = itemArray[itemArray.length-1]
      const num = fileNameFull.lastIndexOf('_')
      const fileNameFirst = fileNameFull.slice(0,num)
      const fileNameLastArray = fileNameFull.split('.');
      const fileNameLast = fileNameLastArray[fileNameLastArray.length-1]
      const fileName = fileNameFirst + '.' + fileNameLast;
      files.value.push({
        name: fileName,
        url: item,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getGuarantee();
});
</script>

<style lang="less" scoped>
.content-warp {
  width: 1920px;
  background-color: #f2f2f2;
  .warp-title {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    background: #f2f2f2;
    margin-top: 70px;
    .warp1-title1 {
      width: 1200px;
      margin: 0 auto;
      background: #fff;
      margin-top: 30px;
      .warp-title-text1 {
        background-color: #f2f2f2;
        color: #f2f2f2;
      }
      .warp-title-text {
        margin-top: 45px;
        margin-left: 90px;
        font-size: 18px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        span {
          display: inline-block;
          width: 19px;
          height: 15px;
          img {
            display: inline-block;
            width: 19px;
            height: 15px;
          }
        }
      }
      .warp-title-content {
        margin-top: 23px;
        margin-left: 90px;
        margin-right: 90px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
      }

      .nva-border {
        border: 1px solid #e1e1e1;
        margin: 29px 50px 58px 50px;
      }
    }
    .warp-img {
      max-width: 961px;
      background-color: #fff;
      margin: 0 auto;
    }
    .warp-img-1 {
      width: 72px;
      height: 18px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #111111;
      margin-top: 30px;
      margin-left: 122px;
    }
    .warp-img-2 {
      height: 18px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #3E8DDD;
      margin-left: 122px;
      margin-top: 10px;
      cursor: pointer;
    }
    .warp-2 {
      height: 40px;
      background-color: #fff;
      color: #fff;
    }
  }
  .warp-i {
    height: 111px;
    background-color: #f2f2f2;
    color: #f2f2f2;
  }
}
</style>
